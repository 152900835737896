import * as React from "react"
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews"
import { PageProps } from "gatsby"

const PreviewPage = ({
  isPreview,
  isLoading,
  ...rest
}: {
  isPreview: boolean
  isLoading: boolean
} & PageProps) => {
  if (isPreview === false) {
    return (
      <>
        <h1 className="text-lg">No preview available.</h1>
      </>
    )
  }

  return (
    <>
      <h1 className="text-lg">Loading...</h1>
    </>
  )
}

export default withPrismicPreviewResolver(PreviewPage)
